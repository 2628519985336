import React, {createContext, useState} from 'react';
import {lightTheme} from "../Utils/CssUtils";



type ThemeContextProviderProps = {
    children: React.ReactNode
}


const ThemeContext = createContext({
    theme: lightTheme,
    setTheme: (theme: any) => {}
});



const ThemeContextProvider = (props: ThemeContextProviderProps) => {
    const [theme, setTheme] = useState(lightTheme);

    return (
        <div>
            <ThemeContext.Provider value={{theme, setTheme}}>
                {props.children}
            </ThemeContext.Provider>
        </div>
    )
}

export {ThemeContext, ThemeContextProvider};