import React from "react";
import {
    GoogleMap,
    Marker,
    Polyline
} from '@react-google-maps/api';

type GoogleMapContainerProps = {
    origin: any,
    destination: any,
    showMap: boolean,
    heightPx: number,
    buyClickHandler: (labelQuoteId: string, labelPrice: number) => void
}
const GoogleMapContainer = (props: GoogleMapContainerProps) => {

    const [buttonLoading, setButtonLoading] = React.useState(false);

    const greenMarker = 'http://maps.google.com/mapfiles/ms/icons/green-dot.png'

    const containerStyle = {
        width: '100%',
        height: `${props.heightPx}px`,
    };

    const getOriginLat = () => {
        return props.origin.geometry.location.lat()
    }

    const getOriginLng = () => {
        return props.origin.geometry.location.lng()
    }

    const getDestinationLat = () => {
        return props.destination.geometry.location.lat()
    }

    const getDestinationLng = () => {
        return props.destination.geometry.location.lng()
    }

    const getOriginFullAddress = () => {
        return props.origin.formatted_address
    }

    const getDestinationFullAddress = () => {
        return props.destination.formatted_address
    }


    const getRoute = () => {
        return [
            {
                lat: getOriginLat(),
                lng: getOriginLng()
            },
            {
                lat: getDestinationLat(),
                lng: getDestinationLng()
            }
        ]
    }


    const getRouteMidpoint = () => {
        const route = getRoute()
        return {
            lat: route[0].lat + (route[1].lat - route[0].lat) / 2,
            lng: route[0].lng + (route[1].lng - route[0].lng) / 2
        }
    }


    const [map, setMap] = React.useState(null)
    const [labelQuoteId, setLabelQuoteId] = React.useState('USPS_xyz123')
    const [labelPrice, setLabelPrice] = React.useState(11.47)

    const onLoad = React.useCallback(function callback(map) {
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    const readyToDisplay = () => {
        return props.origin && props.destination && props.showMap
    }

    const handleBuyClick = () => {
        setButtonLoading(true);
        props.buyClickHandler(labelQuoteId, labelPrice);
        setTimeout(() => {
            setButtonLoading(false);
        }, 3000)
    }

    return <div
        style={{
            marginTop: '5%',
            marginBottom: '5%',
            textAlign: 'center'
        }}
    >
        <div>
            {readyToDisplay() ? (
                <div
                    style={{
                        width: '100%',
                    }}>
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={getRouteMidpoint()}
                        onLoad={onLoad}
                        zoom={2}
                        onUnmount={onUnmount}
                    >
                        <div>
                            <Marker
                                position={{
                                    lat: getOriginLat(),
                                    lng: getOriginLng()
                                }}
                                clickable={true}

                            >
                            </Marker>

                            <Marker
                                position={{
                                    lat: getDestinationLat(),
                                    lng: getDestinationLng()
                                }}
                                clickable={true}
                                icon={greenMarker}
                            >
                            </Marker>

                            <div>

                                <Polyline
                                    path={getRoute()}
                                    options={{
                                        strokeColor: 'blue'
                                    }}
                                />


                            </div>

                        </div>

                    </GoogleMap>
                </div>
            ) : <></>}
        </div>
    </div>


}
export default GoogleMapContainer;
