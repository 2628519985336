import React from "react";
import {Button, Spin, Table} from "antd";
import {ThemeContext} from "../../ContextProviders/ThemeContextProvider";
import {columnTitle, roundToFixed2} from "../../API/Utils";
import {bold} from "../../Utils/HtmlUtils";


const USPS_LOGO = 'https://uspsblog.com/wp-content/uploads/2015/11/USPS_Eagle-Symbol-web-size.png';
const UPS_LOGO = 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/United_Parcel_Service_logo_2014.svg/800px-United_Parcel_Service_logo_2014.svg.png';


type PricingContainerProps = {
    rateDataLoading: boolean,
    rateDataReady: boolean,
    rateData: any,
    buyClickHandler: (labelQuoteId: string, labelPrice: number) => void
}
const RateTableContainer = (props: PricingContainerProps) => {

    const {theme} = React.useContext(ThemeContext);
    const [buttonLoading, setButtonLoading] = React.useState(false);

    const handleBuyClick = (labelQuoteId: string, labelPrice: number) => {
        setButtonLoading(true);
        props.buyClickHandler(labelQuoteId, labelPrice);
        setTimeout(() => {
            setButtonLoading(false);
        }, 3000)
    }


    const RATE_TABLE_COLUMNS = [
        {
            title: columnTitle('Carrier', ''),
            dataIndex: 'carrier_id',
            key: 'carrier_id',
            sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
            sorter: (a: any, b: any) => a.carrier_id - b.carrier_id,
            render: (text: any, record: any) => {

                let logo = record.carrier_id === 'se-1232519' ? USPS_LOGO : UPS_LOGO;


                return <div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-evenly',
                            textAlign: 'center'
                        }}
                    >
                        <div>
                            <img
                                src={logo}
                                alt={'carrier_logo'}
                                style={{
                                    width: '75px',
                                    height: '75px',
                                    borderRadius: '20px',
                                    border: `2px solid ${theme.getBorderColor()}`
                                }}
                            />
                        </div>

                        <div>
                            <p>
                                {bold(record.carrier_friendly_name)}
                            </p>

                        </div>

                    </div>
                </div>
            }

        },
        {
            title: columnTitle('Ship Method', ''),
            dataIndex: 'service_type',
            key: 'service_type',
            sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
            sorter: (a: any, b: any) => a.service_type - b.service_type,
            render: (text: any, record: any) => {

                return (<div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        textAlign: 'center'
                    }}
                >
                    <div>

                        <p>
                            {bold('Ship Method: ')}{record.service_type}
                        </p>

                        <p>
                            {bold('Package Type: ')}{record.package_type}
                        </p>

                        <p>
                            {bold('Guaranteed: ')}{record.guaranteed_service ? 'Yes' : 'No'}
                        </p>

                        <p>
                            {bold('Zone: ')}{record.zone}
                        </p>

                    </div>
                </div>)
            }

        },
        {
            title: columnTitle('Delivery', ''),
            dataIndex: 'delivery_days',
            key: 'delivery_days',
            sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
            sorter: (a: any, b: any) => a.delivery_days - b.delivery_days,
            render: (text: any, record: any) => {

                return (<div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        textAlign: 'center',
                        height: '100px'
                    }}
                >
                    <div>

                        <p>
                            {bold('Delivery Date: ')}{record.estimated_delivery_date.substring(0, 10)}
                        </p>

                        <p>
                            {bold('Delivery Days: ')}{record.delivery_days}
                        </p>

                    </div>
                </div>)
            }

        },

        {
            title: columnTitle('Price', ''),
            dataIndex: 'rate_amount_usd',
            key: 'rate_amount_usd',
            sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
            sorter: (a: any, b: any) => a.rate_amount_usd - b.rate_amount_usd,
            render: (text: any, record: any) => {

                return (<div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        textAlign: 'center',
                        height: '100px'
                    }}
                >
                    <div>

                        <p>
                            {bold('$')}{bold(record.rate_amount_usd)}
                        </p>

                    </div>

                    <div>
                        <Button
                            variant={'solid'}
                            color={'danger'}
                            loading={buttonLoading}
                            onClick={() => handleBuyClick(record.rate_id_ship_engine, record.rate_amount_usd)}

                        >
                            <p>Buy</p>
                        </Button>
                    </div>
                </div>)
            }

        }

    ];


    return <div
        style={{
            marginTop: '2%',
            marginBottom: '5%',
            textAlign: 'center',
            width: '100%'
        }}
    >
        {
            props.rateDataReady ? <Table
                dataSource={props.rateData}
                columns={RATE_TABLE_COLUMNS}
                loading={props.rateDataLoading}
                pagination={{pageSize: 25}}

            /> : null
        }

        {
            props.rateDataLoading ? <Spin/> : null
        }


    </div>


}
export default RateTableContainer;
