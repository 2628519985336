import React from "react";
import {bold, boldLink} from "../../Utils/HtmlUtils";


const TermsOfServiceContainer = () => {


    return (
        <div
            style={{
                marginTop: "2%",
                width: "50%",
                marginLeft: "25%",
                marginBottom: "5%",
            }}
            id={"rate-file-tool-container"}
        >
            {/*Add list item stylesheet*/}
            <style>
                {`
                        
                      #rate-file-tool-container  ul li {
                            margin-bottom: 15px;
                        }
                    `}
            </style>
            <div
                style={{
                    borderBottom: '1px solid black',
                    marginBottom: "2%",
                }}
            >

                <h1
                    style={{
                        marginTop: "2%",
                    }}
                >Terms of Service</h1>
                <p>
                    Last updated on {bold('December 12th, 2024')}
                </p>
            </div>

            <h3>Acceptance of Terms</h3>

            <p>
                By accessing and using our services at NoPostOffice.com (owned by Perseuss LLC), you acknowledge that
                you have read,
                understood, and agree to be bound by these Terms of Use. If you do not accept these terms, do not use
                our services.
            </p>

            <h3>Changes to Terms</h3>

            <p> Perseuss LLC reserves the right to modify these Terms at any time. Your continued use of the
                services after any changes constitutes your acceptance of the new Terms.</p>


            <h3>Privacy Policy</h3>

            <p>
                Our {boldLink(
                'Privacy Policy',
                '/privacy'
            )} describes how we handle the information you provide to us when you use our services.
                You understand that through your use of the services, you consent to the collection and use of this
                information.
            </p>

            <h3>Your Account</h3>


            <p>
                You are responsible for safeguarding your account, and you agree not to disclose your password to any
                third party. You must notify us immediately upon becoming aware of any breach of security or
                unauthorized use of your account.
            </p>


            <h3>Intellectual Property Rights</h3>

            <p> All intellectual property rights in the services and content provided by Perseuss LLC are owned by
                us or our licensors. You may not use content from our services unless you obtain permission from us or
                are
                otherwise permitted by law.</p>


            <h3>Use of Services</h3>

            <p>
                The services must not be used for any illegal or unauthorized purpose. You must not, in the use of the
                service, violate any laws in your jurisdiction.
            </p>


            <h3>User Content</h3>


            <p>
                You retain ownership over all content that you submit to the service, but you agree to grant Perseuss
                LLC a non-exclusive, worldwide, royalty-free license to use, copy, reproduce, process, and display
                your content in connection with the service.
            </p>

            <h3>Use and Sharing of Customer Data</h3>

            <p>
                By using our services, you agree that we may collect, process, and use the information you provide for
                the purpose of delivering and improving our services. Additionally, you grant us the right to create
                aggregated, anonymized, or de-identified data derived from your information. Such aggregated data will
                not identify you or disclose any personally identifiable information.
            </p>

            <p>
                We may use and share this aggregated data, including through resale to third parties, for research,
                analytics, product development, marketing, and other legitimate business purposes.
                We will not share identifiable personal data with third parties without your explicit consent, except as
                required by law.
            </p>

            <h3>
                Termination
            </h3>

            <p>
                Perseuss LLC may terminate or suspend your access to the services immediately, without prior notice
                or liability, if you breach the Terms.
            </p>

            <h3>
                Disclaimer and Limitation of Liability
            </h3>

            <p>

                The services are provided on an "as is" and "as available" basis. Perseuss LLC expressly disclaims
                all warranties of any kind. In no event shall Perseuss LLC be liable for any indirect, incidental,
                special, consequential or punitive damages resulting from the use of or inability to use the services.
            </p>


            <h3>Governing Law</h3>


            <p>
                These Terms shall be governed by the laws of New Jersey, without respect to its conflict of laws
                principles.
            </p>


        </div>
    )

}

export default TermsOfServiceContainer;
