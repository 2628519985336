import * as React from 'react';
import {Redirect} from 'react-router-dom';
import {Auth} from 'aws-amplify';
import {LoadingOutlined, LockOutlined, MailOutlined} from "@ant-design/icons";
import {Button, Input, Spin} from "antd";
import {boldLink} from "../../Utils/HtmlUtils";


const SignUpContainer = () => {

    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [redirect, setRedirect] = React.useState(false);

    const buttonDisabled = (): boolean => {
        return !email || !password || !confirmPassword || password !== confirmPassword || loading;
    }


    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        if (!email || !password || !confirmPassword) {
            alert('Please fill in all fields');
            return
        }

        if (password !== confirmPassword) {
            alert('Passwords do not match');
            return;
        }


        // show loader
        Auth.signUp({
            username: email,
            password,
            attributes: {
                email
            }
        })
            .then(() => {

                setEmail(email);
                setLoading(false);
                alert(`User ${email} has been signed up! We will get in touch with you! If you have questions, please email tom@nopostoffice.com :) `);
                setRedirect(true);
            })
            .catch(err => {
                alert(JSON.stringify({
                    message: 'Error',
                    description: 'Error signing up user',
                    err
                }));

                setLoading(false);
            });
    }


    return (
        <div>
            <div>

            </div>

            {/*TODO: Once we figure out a signup form, we will use a manual email for to get clients*/}
            <div
                style={{
                    width: '50%',
                    marginLeft: '25%',
                    marginTop: '2%',
                    textAlign: 'center',
                }}
            >
                <div>
                    <h1> Sign up for Premium Trial!</h1>

                </div>
                <div
                    style={{
                        display: 'flex',
                        marginTop: '5%',
                        height: "200px",
                        width: "70%",
                        marginLeft: "15%",
                        flexDirection: "column",
                        justifyContent: 'space-between',
                    }}
                >


                    <div>
                        <Input prefix={<MailOutlined/>}
                               placeholder="Email"
                               onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>


                    <div>

                        <Input
                            prefix={<LockOutlined/>}
                            type="password"
                            placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)}
                        />

                    </div>

                    <div>
                        <Input
                            prefix={<LockOutlined/>}
                            type="password"
                            placeholder="Confirm Password"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                    </div>

                    <div>
                        <Button
                            style={{width: '100%'}}
                            type="primary"
                            disabled={buttonDisabled()}
                            onClick={handleSubmit}

                        >
                            {loading ? <Spin
                                indicator={<LoadingOutlined spin/>}/> : 'Sign Up!'}
                        </Button>
                    </div>

                    <div>
                        {redirect && (

                            <Redirect
                                to={{
                                    pathname: '/rates',
                                }}
                            />
                        )}
                    </div>

                </div>
            </div>
        </div>
    )
}


export default SignUpContainer;
