import * as React from "react";
import {ThemeContext} from "../../ContextProviders/ThemeContextProvider";
import {LinkedinFilled, MailFilled, PhoneFilled} from "@ant-design/icons";
import {boldLink} from "../../Utils/HtmlUtils";

type FooterProps = {}

const Footer: React.SFC<FooterProps> = (props) => {

    const {theme, setTheme} = React.useContext(ThemeContext);

    return <div
        style={{
            zIndex: 100,
            width: "100%",
            height: '205px',
            marginLeft: "0%",
            borderBottom: `1px solid ${theme.getBorderColor()}`,
            backgroundColor: theme.getHeaderBackgroundColor(),
            color: theme.getHeaderTextColor(),
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            textAlign: 'center',

        }}
    >
        <div
            style={{
                marginTop: '1%',
            }}
        >
            <p>All Rights Reserved © 2024 <a
                href={'https://www.linkedin.com/company/perseuss-llc/'}
                target={'_blank'}
            >
                Perseuss LLC <LinkedinFilled/>
            </a>
            </p>
            <p><PhoneFilled/> +1 (206) 356-8911 | <MailFilled/> tom@nopostoffice.com</p>

            <p>Handcrafted with  ❤️  in New Jersey 🗽</p>


            <p>
                {
                    boldLink(
                        'Terms of Service',
                        '/terms'
                    )} | {
                boldLink(
                    'Privacy Policy',
                    '/privacy'
                )
            }
            </p>

        </div>


    </div>
}

export default Footer
