import React, {useState} from "react";
import Autocomplete from "react-google-autocomplete";
import {bold} from "../../Utils/HtmlUtils";
import {Button, Input} from "antd";
import GoogleMapContainer from "../GoogleMapContainer";
import {PaymentContainer} from "../PaymentContainer";
import {getPaymentEnv} from "../../Utils/ApiEnv";
import {ThemeContext} from "../../ContextProviders/ThemeContextProvider";
import {CloseCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {backendCall} from "../../API/Utils";
import RateTableContainer from "../RateTableContainer";

type AppContainerProps = {
    blogWidthPercent: number;
    contentWidthPercent: number;
    videoHeightPx: number;
    fontSizePx: number;
}


const AppContainerDesktop = (props: AppContainerProps) => {

    const {theme} = React.useContext(ThemeContext);

    const [origin, setOrigin] = useState(null as any)
    const [destination, setDestination] = useState(null as any)

    const [boxWeightLbs, setBoxWeightLbs] = useState(0.0)
    const [boxWeightOz, setBoxWeightOz] = useState(0.0)


    const [boxLength, setBoxLength] = useState(0.0)
    const [boxWidth, setBoxWidth] = useState(0.0)
    const [boxHeight, setBoxHeight] = useState(0.0)

    const [cargoVolume, setCargoVolume] = useState(0.0)
    const [cargoDescription, setCargoDescription] = useState("")

    const [loading, setLoading] = useState(false)
    const [showMap, setShowMap] = useState(false)

    const autocompleteWidthPercent = 60
    const autocompleteHeightPx = 35
    const autocompleteTextSizePx = 17

    const [selectedLabelQuoteId, setSelectedLabelQuoteId] = useState("")
    const [selectedLabelPrice, setSelectedLabelPrice] = useState(0)
    const [showPaymentPage, setShowPaymentPage] = useState(false)

    const mapRef = React.useRef(null);
    const creditCardRef = React.useRef(null);

    const [rateData, setRateData] = useState([] as any[])
    const [rateDataLoading, setRateDataLoading] = useState(false)
    const [rateDataReady, setRateDataReady] = useState(false)


    const handleLabelQuoteBuyClick = (labelQuoteId: string, labelPrice: number) => {
        setSelectedLabelQuoteId(labelQuoteId);
        setSelectedLabelPrice(labelPrice);
        setShowPaymentPage(true);
        scrollToElement(creditCardRef, 1500);
    }

    const scrollToElement = (ref: any, delayMs: number = 100) => {
        setTimeout(() => {
            if (ref.current) {
                // @ts-ignore
                ref.current.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
            }
        }, delayMs)
    }

    const handleSearchClick = () => {
        setLoading(true);
        setRateDataLoading(true);

        backendCall(
            'post-rate-shop',
            'POST',
            {},
            {
                payment_env: getPaymentEnv(),
                customer_id: 'UI_Test_01',
                address_from: origin.address_components,
                address_to: destination.address_components,
                packages: [
                    {
                        'weight': {
                            'value': boxWeightLbs + boxWeightOz / 16,
                            'unit': 'pound'
                        },
                        'dimensions': {
                            'unit': 'inch',
                            'length': boxLength,
                            'width': boxWidth,
                            'height': boxHeight
                        }
                    }
                ]

            }
        ).then((r: any) => {
                try {
                    console.log(r);
                    if (r['result']) {
                        setRateDataReady(true);
                        setRateData(r['result']['response_body']['rates']);
                    }
                } catch (e) {
                    console.log('Error = ', e)
                    alert(`Error fetching stats: ${e}`,)
                } finally {
                    setLoading(false);
                    setRateDataLoading(false);
                }
            }
        )
    }


    return (
        <div
            style={{
                marginTop: "2%",
                width: `${props.blogWidthPercent}%`,
                marginLeft: `${(100 - props.blogWidthPercent) / 2}%`,
                textAlign: "left"
            }}
            id={"blog-container"}

        >
            {/*Add list item stylesheet*/}
            <style>
                {`#blog-container  ul li {
                            margin-bottom: 15px;
                        }
                  #blog-container ol li {
                            margin-bottom: 15px;
                        }      
                `}
            </style>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <div
                    style={{
                        textAlign: "center",
                        width: "40%"
                    }}
                >
                    <h2>NO MORE WAITING!</h2>
                    <h3>Skip the line at the POST OFFICE</h3>
                    <h3>Get your optimally-priced labels in 60 seconds</h3>
                    <h3>From the comfort of your home</h3>
                    <h3>And go straight to the nearest drop-off location

                    </h3>

                    <img
                        src={"https://news.usps.com/wp-content/uploads/2017/06/APD_large-story.jpg"}
                        alt={"fast shipping"}
                        style={{
                            width: "100%",
                            marginTop: "5%",
                            borderRadius: "10px",
                            border: `2px solid ${theme.getBorderColor()}`
                        }}

                    />

                </div>


                <div
                    style={{
                        width: "40%",
                        textAlign: "center",
                        border: `2px solid ${theme.getTextColor()}`,
                        borderRadius: "10px",
                        padding: "2%",
                        backgroundColor: theme.getSecondaryBackgroundColor()
                    }}
                >

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            marginTop: "5%"
                        }}
                    >
                        <label
                            style={{
                                width: `20%`,
                            }}
                        >{bold('From')}</label>
                        <Autocomplete
                            style={{
                                width: `${autocompleteWidthPercent}%`,
                                marginLeft: `${(100 - autocompleteWidthPercent) / 2}%`,
                                height: `${autocompleteHeightPx}px`,
                                fontSize: `${autocompleteTextSizePx}px`
                            }}
                            options={{
                                fields: ['ALL'],
                                types: ['address']
                            }}
                            placeholder={'Address, City, State'}
                            onPlaceSelected={(place) => {
                                setOrigin(place);
                                console.log(place);
                            }}
                        />
                    </div>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            marginTop: "3%"
                        }}
                    >
                        <label
                            style={{
                                width: `20%`,
                            }}
                        >{bold('To')}</label>
                        <Autocomplete
                            style={{
                                width: `${autocompleteWidthPercent}%`,
                                marginLeft: `${(100 - autocompleteWidthPercent) / 2}%`,
                                height: `${autocompleteHeightPx}px`,
                                fontSize: `${autocompleteTextSizePx}px`
                            }}
                            placeholder={'Address, City, State'}
                            options={{
                                fields: ['ALL'],
                                types: ['address']
                            }}
                            onPlaceSelected={(place) => {
                                setDestination(place);
                                console.log(place);
                            }}
                        />
                    </div>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginTop: "3%"
                        }}
                    >
                        <label
                            style={{
                                width: `20%`,
                            }}
                        >{bold('Weight')}</label>


                        <div
                            style={{
                                width: `${autocompleteWidthPercent}%`,
                                marginLeft: `${(100 - autocompleteWidthPercent) / 2}%`,
                                height: `${autocompleteHeightPx}px`,
                                fontSize: `${autocompleteTextSizePx}px`,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                            }}

                        >

                            <Input
                                style={{
                                    width: `40%`,
                                    height: `${autocompleteHeightPx}px`,
                                    fontSize: `${autocompleteTextSizePx}px`
                                }}
                                type={'number'}
                                placeholder={'Pounds'}
                                onChange={(e) => {
                                    setBoxWeightLbs(parseInt(e.target.value))
                                }}
                            />

                            <PlusCircleOutlined/>

                            <Input
                                style={{
                                    width: `40%`,
                                    height: `${autocompleteHeightPx}px`,
                                    fontSize: `${autocompleteTextSizePx}px`
                                }}
                                type={'number'}
                                placeholder={'Ounces'}
                                onChange={(e) => {
                                    setBoxWeightOz(parseInt(e.target.value))
                                }}
                            />
                        </div>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                            marginTop: "3%"
                        }}
                    >
                        <div
                            style={{
                                width: `20%`
                            }}
                        >{bold('Dimensions')} (inches)
                        </div>
                        <div
                            style={{
                                width: `${autocompleteWidthPercent}%`,
                                marginLeft: `${(100 - autocompleteWidthPercent) / 2}%`,
                                height: `${autocompleteHeightPx}px`,
                                fontSize: `${autocompleteTextSizePx}px`,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between"
                            }}
                        >
                            <Input
                                style={{
                                    width: `30%`,
                                }}
                                type={'number'}
                                placeholder={'Length'}
                                onChange={(e) => {
                                    setBoxLength(parseInt(e.target.value))
                                }}
                            />

                            <CloseCircleOutlined/>

                            <Input
                                style={{
                                    width: `30%`,
                                }}
                                type={'number'}
                                placeholder={'Width'}
                                onChange={(e) => {
                                    setBoxWidth(parseInt(e.target.value))
                                }}
                            />

                            <CloseCircleOutlined/>

                            <Input
                                style={{
                                    width: `30%`,
                                }}
                                type={'number'}
                                placeholder={'Height'}
                                onChange={(e) => {
                                    setBoxHeight(parseInt(e.target.value))
                                }}
                            />

                        </div>
                    </div>

                    <div
                        style={{
                            marginTop: '5%',
                            marginBottom: '5%',
                            textAlign: 'center',
                            width: '80%',
                            minHeight: '200px',
                            marginLeft: '10%'
                        }}
                        ref={mapRef}
                    >
                        <GoogleMapContainer
                            origin={origin}
                            destination={destination}
                            showMap={origin && destination}
                            heightPx={200}
                            buyClickHandler={handleLabelQuoteBuyClick}
                        />
                    </div>


                    <div
                        style={{
                            textAlign: 'center',
                            marginTop: '5%'
                        }}
                    >
                        <Button
                            type={'primary'}
                            loading={loading}
                            disabled={!origin || !destination || !boxWeightLbs}
                            onClick={handleSearchClick}
                            style={{
                                fontSize: '20px',
                                width: '50%',
                                height: '50px',
                            }}
                        >
                            See Shipping Rates
                        </Button>

                    </div>
                </div>
            </div>

            <div>
                <RateTableContainer
                    rateDataLoading={rateDataLoading}
                    rateDataReady={rateDataReady}
                    rateData={rateData}
                    buyClickHandler={handleLabelQuoteBuyClick}
                />


            </div>


            <div>

                <PaymentContainer
                    showPaymentPage={showPaymentPage}
                    labelPrice={selectedLabelPrice}
                    labelQuoteId={selectedLabelQuoteId}
                    paymentEnv={getPaymentEnv()}
                />
            </div>

            <div
                style={{
                    height: "100px"
                }}
                ref={creditCardRef}
            >

            </div>


        </div>
    );

}

export default AppContainerDesktop;
