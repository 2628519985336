import * as React from 'react';

export const boldLink = (text: any, link: any, color: string | undefined = undefined, fontSize: number | undefined = undefined) => {
    return <b>
        <a
            href={link}
            target={"_blank"}
            rel={"noreferrer noopener"}
            style={{
                color: color,
                fontSize: fontSize
            }}
        >{text}</a>
    </b>
}

export const bold = (text: any, color: string | undefined = undefined, fontSize: number | undefined = undefined) => {
    return <b style={{
        color: color,
        fontSize: fontSize
    }}>{text}</b>
}
