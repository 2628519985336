import * as React from "react";
import {Menu, Switch} from 'antd';
import {useHistory, useLocation} from "react-router-dom";
import {useEffect} from "react";
import {ThemeContext} from "../../ContextProviders/ThemeContextProvider";
import {DollarTwoTone, QuestionCircleTwoTone, RocketTwoTone, UnlockTwoTone} from "@ant-design/icons";
import {getThemeByName} from "../../Utils/CssUtils";
import {bold, boldLink} from "../../Utils/HtmlUtils";

type NavigationProps = {}

const Navigation: React.SFC<NavigationProps> = (props) => {
    const history = useHistory();
    const location = useLocation()
    const fontSize = 20

    const [selectedMenuItems, setSelectedMenuItems] = React.useState<Array<any>>([''])

    const {theme, setTheme} = React.useContext(ThemeContext);

    useEffect(() => {
        if (location.pathname) {
            let path = location.pathname.split('/')[1]
            if (!path) {
                path = 'rates'
            }
            setSelectedMenuItems([path])
        }
    }, [location.pathname])

    const handleClick = (e: any) => {
        const selection = e.key

        if (selection !== 'theme') {
            setSelectedMenuItems([selection])
            history.push(`/${selection}`)
        }

    }

    return <div
        style={{
            position: 'sticky',
            top: 0,
            zIndex: 100,
            width: "100%",
            height: '55px',
            marginLeft: "0%",
            borderBottom: `1px solid ${theme.getBorderColor()}`,
            backgroundColor: theme.getHeaderBackgroundColor(),
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            textAlign: 'center',

        }}
    >
        <div
            style={{
                width: '40%',
            }}
        >
            <Menu
                onClick={handleClick}
                selectedKeys={selectedMenuItems}
                mode="horizontal"
                style={{
                    backgroundColor: theme.getHeaderBackgroundColor(),
                    color: theme.getTextColor(),
                }}
            >
                {/*TODO: Once I am ready, I will enable the LIGHT / DARK mode features...*/}
                <div
                    style={{
                        display: 'none',
                    }}
                >
                    <Menu.Item key="theme">
                        <Switch
                            checkedChildren="Light"
                            unCheckedChildren="Dark"
                            checked={theme.getThemeName() === 'light'}
                            disabled={true}
                            onClick={(e) => {
                                if (theme.getThemeName() === 'light') {
                                    setTheme(getThemeByName('dark'));
                                } else {
                                    setTheme(getThemeByName('light'));
                                }
                            }}
                        />
                    </Menu.Item>
                </div>


                <Menu.Item key="rates">
                    <RocketTwoTone style={{fontSize: 20}}/>
                    <span>
                    {bold('Rates', theme.getHeaderTextColor(), 20)}
                </span>
                </Menu.Item>

                {/*TODO: Once I have the Tutorial Video, I will upload it... */}
                <Menu.Item key="tutorial" style={{
                    display: 'none',
                }}>
                    <QuestionCircleTwoTone style={{fontSize: fontSize}}/>
                    <span>
                    {bold('Tutorial', theme.getHeaderTextColor(), fontSize)}
                </span>
                </Menu.Item>

                <Menu.Item key="pricing">
                    <DollarTwoTone style={{fontSize: fontSize}}/>
                    <span>
                    {bold('Pricing', theme.getHeaderTextColor(), fontSize)}
                </span>
                </Menu.Item>

                <Menu.Item key="signup">
                    <UnlockTwoTone style={{fontSize: fontSize}}/>
                    <span>
                    {bold('Sign Up', theme.getHeaderTextColor(), fontSize)}
                </span>
                </Menu.Item>

            </Menu>
        </div>

        <div
            style={{
                width: '40%',
            }}

        >
            <p
                style={{
                    color: theme.getHeaderTextColor(),
                    fontSize: fontSize - 5,
                }}
            >For questions, feedback and business inquiries send email to{boldLink(
                ' tom@nopostoffice.com',
                'mailto:tom@nopostoffice.com',
                theme.getHeaderTextColor(),
            )}
            </p>
        </div>
    </div>
}

export default Navigation
