import * as React from 'react';
import {useStripe, useElements, Elements, PaymentElement} from '@stripe/react-stripe-js';
import {loadStripe} from "@stripe/stripe-js";
import {Button, Spin} from "antd";
import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {backendCall} from "../../API/Utils";
import {PaymentEnv} from "../../Utils/ApiEnv";


type PaymentContainerProps = {
    labelQuoteId: string,
    labelPrice: number,
    paymentEnv: PaymentEnv
    showPaymentPage: boolean,
}

class PaymentIntent {
    private readonly clientSecret: string;
    private readonly publicKey: string;
    private readonly intentId: string;
    private readonly amount: number;
    private readonly metadata: any;

    public constructor(
        clientSecret: string,
        publicKey: string,
        intentId: string,
        amount: number,
        metadata: any
    ) {
        this.clientSecret = clientSecret;
        this.publicKey = publicKey;
        this.intentId = intentId;
        this.amount = amount;
        this.metadata = metadata;
    }

    public getClientSecret() {
        return this.clientSecret;
    }

    public getPublicKey() {
        return this.publicKey;
    }

    public getIntentId() {
        return this.intentId;
    }

    public getAmount() {
        return this.amount;
    }

    public getMetadata() {
        return this.metadata;
    }


}

type CardProps = {
    paymentIntent: PaymentIntent
}


export const PaymentContainer: React.FunctionComponent<PaymentContainerProps> = (props) => {

    const [paymentIntentReady, setPaymentIntentReady] = useState(false);
    const [paymentIntent, setPaymentIntent] = useState({} as PaymentIntent);

    useEffect(() => {
        if (props.showPaymentPage && props.labelQuoteId && props.labelPrice) {
            // Fetch Payment Intent
            fetchPaymentIntent(props.labelQuoteId, props.labelPrice)
        }


    }, [props.showPaymentPage, props.labelQuoteId, props.labelPrice])

    const fetchPaymentIntent = (labelQuoteId: string, labelPrice: number) => {
        backendCall('get-label-payment-intent','POST', {}, {
            'label_quote_id': labelQuoteId,
            'label_price_usd': labelPrice,
            'payment_env': props.paymentEnv.toString()

        }, ).then((response: any) => {

            if (response.error) {
                console.error(response.error)
                alert(`Error when calling Stripe: ${response.error}`)
                setPaymentIntentReady(false);

            } else {

                let result = response.result.response_body;
                let newPaymentIntent = new PaymentIntent(
                    result['client_secret'],
                    result['public_key'],
                    result['intent_id'],
                    result['price_usd'],
                    result['metadata']
                )
                setPaymentIntent(newPaymentIntent)

                console.log('Payment Intent = ', newPaymentIntent)

            }


            setPaymentIntentReady(true);
        })
    }


    return (
        <div>

            {/*Only show Credit Card input if payment intent is ready and user clicked to buy*/}
            {
                props.showPaymentPage && paymentIntentReady ?
                    <Elements
                        stripe={loadStripe(paymentIntent.getPublicKey())}
                        options={{
                            clientSecret: paymentIntent.getClientSecret(),
                            appearance: {theme: 'stripe'}
                        }}
                    >
                        <CardContent paymentIntent={paymentIntent}/>

                    </Elements>
                    :
                    null
            }

            {
                props.showPaymentPage && !paymentIntentReady ?
                    <Spin size={'large'}/>
                    :
                    null
            }
        </div>


    );
};


const CardContent: React.FunctionComponent<CardProps> = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const history = useHistory();


    const [loading, setLoading] = useState(false);


    // For STRIPE Payments
    const handleStripePayment = async (e: any) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        e.preventDefault();
        setLoading(true);

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            console.error('Stripe.js has not yet loaded.');
            return;
        }


        const {error: stripeError} = await stripe.confirmPayment({
            elements: elements as any,
            confirmParams: {
                return_url: `${origin}/payment-confirmed/${props.paymentIntent.getIntentId()}`
            }
        });

        if (stripeError) {
            // Show error to your customer (e.g., insufficient funds)
            console.error(stripeError.message);
            alert(stripeError.message);

        } else {
            alert('Payment Succeeded');
        }

        setLoading(false);

    };


    return (
        <div>

            <div style={{
                textAlign: 'center',
                width: "100%",

            }}>
                <div style={{
                    marginTop: '7%',
                    marginBottom: '3%',
                    padding: '5%',
                    borderStyle: 'solid',
                    borderColor: 'gray',
                    borderWidth: '1px',
                    borderRadius: '5%'
                }}>
                    <h3>Price Breakdown</h3>
                    <p style={{
                        paddingTop: '3%',
                        borderTopStyle: 'solid'
                    }}>
                        <span style={{fontWeight: 'bold'}}>Label Price: </span>
                        <span>${props.paymentIntent.getAmount()} </span>
                    </p>


                </div>

                <PaymentElement id="card"/>
                <div>
                    <Button
                        type="primary"
                        onClick={handleStripePayment}
                        style={{
                            marginTop: '5%'
                        }}
                        loading={loading}
                    >
                        Buy Label for ${props.paymentIntent.getAmount()}
                    </Button>
                </div>

            </div>

        </div>
    )
}