import React from "react";
import {bold} from "../../Utils/HtmlUtils";


const PrivacyPolicyContainer = () => {


    return (
        <div
            style={{
                marginTop: "2%",
                width: "50%",
                marginLeft: "25%",
                marginBottom: "5%",
            }}
            id={"rate-file-tool-container"}
        >
            {/*Add list item stylesheet*/}
            <style>
                {`
                        
                      #rate-file-tool-container  ul li {
                            margin-bottom: 15px;
                        }
                    `}
            </style>
            <div
                style={{
                    borderBottom: '1px solid black',
                    marginBottom: "2%",
                }}
            >

                <h1
                    style={{
                        marginTop: "2%",
                    }}
                >Privacy Policy</h1>
                <p>
                    Last updated on {bold('December 12th, 2024')}
                </p>
            </div>


            <p>
                This Privacy Policy describes how Perseuss LLC ("Perseuss", "we", “us” or "our") handle
                personal information that we collect through our digital properties that link to this Privacy Policy,
                including our website (collectively, the “Service”), as well as through social media, our marketing
                activities, and other activities described in this Privacy Policy.
            </p>

            <p>
                By downloading, accessing or using our Service, you signify that you have read, understood, and agree to
                the contents of this Policy and acknowledge that your access to the Service is at all times subject to
                this Policy. Beyond the Policy, your use of our Service is also subject to our Terms & Conditions on the
                website.
            </p>


            <h3>Personal Information We Collect</h3>

            <p>
                Personal information you may provide to us through the Service or otherwise includes:
            </p>

            <ul>
                <li>
                    Contact data, such as your first and last name, email address, professional title, company name, and
                    phone number.
                </li>
                <li>
                    Profile data, such as the username and password that you may set to establish an online account on
                    the Service.
                </li>
                <li>
                    Other data not specifically listed here, which we will use as described in this Privacy Policy or as
                    otherwise disclosed at the time of collection.
                </li>
            </ul>


            <h3>Third-Party Sources</h3>

            <p>
                We may combine personal information we receive from you with personal information we obtain from other
                sources, such as:
            </p>

            <ul>
                <li>
                    Cloud providers, such as utilization statistics for Amazon Web Services and Google Cloud Platform
                    servers and the amount you spend to access such services after you authorize access. Cookies and
                    similar technologies. Some of the automatic collection described above is facilitated by the
                    following technologies:
                </li>
                <li>
                    Cookies, which are small text files that websites store on user devices and that allow web servers
                    to record users’ web browsing activities and remember their submissions, preferences, and login
                    status as they navigate a site. Cookies used on our sites include both “session cookies” that are
                    deleted when a session ends, “persistent cookies” that remain longer, “first party” cookies that we
                    place and “third party” cookies that our third-party business partners and service providers place.
                </li>
                <li>
                    Local storage technologies, like HTML5, that provide cookie-equivalent functionality but can store
                    larger amounts of data on your device outside of your browser in connection with specific
                    applications.
                </li>
                <li>
                    Web beacons, also known as pixel tags or clear GIFs, which are small image files that may be
                    embedded
                    on web pages, videos, or in our emails to help deliver cookies, count visits, and understand usage
                    and campaign effectiveness.
                </li>
            </ul>


            <h3>How we use your personal information </h3>

            <p>
                We may use your personal information for the following purposes or as otherwise described at the time of
                collection:
            </p>


            <h3>Service delivery</h3>

            <p>We may use your personal information to:</p>
            <ul>
                <li>
                    Provide, operate and improve the Service and our business.
                </li>
                <li>
                    Communicate with you about the Service, including by sending announcements, updates, security
                    alerts, and support and administrative messages.
                </li>

            </ul>

            <h3>Compliance and protection </h3>
            <p>
                We may use your personal information to:
            </p>

            <ul>
                <li>
                    Comply with applicable laws, lawful requests, and legal process, such as to respond to subpoenas or
                    requests from government authorities;
                </li>
                <li>
                    Protect our, your or others’ rights, privacy, safety or property (including by making and defending
                    legal claims);
                </li>
                <li>
                    Audit our internal processes for compliance with legal and contractual requirements or our internal
                    policies;
                </li>
                <li>
                    Enforce the terms and conditions that govern the Service; and
                </li>
                <li>
                    Prevent, identify, investigate and deter fraudulent, harmful, unauthorized, unethical or illegal
                    activity, including cyberattacks and identity theft.
                </li>

            </ul>

            <h3> With your consent </h3>
            <p>
                In some cases, we may specifically ask for your consent to collect, use or share your personal
                information, such as when required by law.
            </p>


            <h3> Cookies and similar technologies </h3>
            <p>
                In addition to the other uses included in this section, we may use the Cookies and similar technologies
                described above for the following purposes:
            </p>
            <ul>
                <li>
                    Technical operation. To allow the technical operation of the Service.
                </li>
                <li>
                    Functionality. To enhance the performance and functionality of our services.
                </li>
                <li>
                    Advertising. To help our third-party advertising partners collect information about how you use the
                    Service and other online services over time, which they use to show you ads on other online services
                    they believe will interest you and measure how the ads perform.
                </li>
                <li>
                    Analytics. To help us understand user activity on the Service, including which pages are most and
                    least visited and how visitors move around the Service, as well as user interactions with our
                    emails.
                </li>
            </ul>

            <h3>
                How we share your personal information
            </h3>

            <p>
                We may share your personal information with the following parties and as otherwise described in this
                Privacy Policy or at the time of collection:
            </p>

            <ul>
                <li>
                    Affiliates. Our corporate parent, subsidiaries, and affiliates, for purposes consistent with this
                    Privacy Policy.
                </li>
                <li>
                    Service providers. Third parties that provide services on our behalf or help us operate the Service
                    or our business (such as hosting, information technology, customer support, email delivery, consumer
                    research, marketing, and website analytics).
                </li>
                <li>
                    Payment processors. Any payment card information you use to make a purchase on the Service is
                    collected and processed directly by our payment processor (see: https://stripe.com/privacy).
                </li>
                <li>
                    Advertising partners. Third-party advertising companies for the interest-based advertising purposes
                    described above.
                </li>
                <li>
                    Professional advisors. Professional advisors, such as lawyers, auditors, bankers and insurers, where
                    necessary in the course of the professional services that they render to us.
                </li>

                <li>
                    Authorities and others. Law enforcement, government authorities, and private parties, as we believe
                    in good faith to be necessary or appropriate for the compliance and protection purposes described
                    above.
                </li>

                <li>
                    Business transferees. Acquirers and other relevant participants in business transactions (or
                    negotiations of or due diligence for such transactions) involving a corporate divestiture, merger,
                    consolidation, acquisition, reorganization, sale or other disposition of all or any portion of the
                    business or assets of, or equity interests in, Perseuss LLC, our parent, our affiliates, or our
                    subsidiaries (including, in connection with a bankruptcy or similar proceedings).
                </li>

                <li>

                    Finally, by using our services, you agree that we may collect, process, and use the information you
                    provide for the purpose of delivering and improving our services. Additionally, you grant us the
                    right to create aggregated, anonymized, or de-identified data derived from your information. Such
                    aggregated data will not identify you or disclose any personally identifiable information. We may
                    use and share this aggregated data, including through resale to third parties, for research,
                    analytics, product development, marketing, and other legitimate business purposes.
                    We will not share identifiable personal data with third parties without your explicit consent,
                    except as required by law.
                </li>
            </ul>


            <h3>
                Other sites and services
            </h3>


            <p>
                The Service may contain links to websites, mobile applications, and other online services operated by
                third parties. In addition, our content may be integrated into web pages or other online services that
                are not associated with us. These links and integrations are not an endorsement of, or representation
                that we are affiliated with, any third party. We do not control websites, mobile applications or online
                services operated by third parties, and we are not responsible for their actions. We encourage you to
                read the privacy policies of the other websites, mobile applications and online services you use.
            </p>


            <h3>Security</h3>


            <p>
                We employ a number of technical, organizational and physical safeguards that are designed to improve the
                integrity and security of information that we collect and maintain. Please be aware that no security
                measures are perfect or impenetrable. We cannot and do not guarantee that information about you will not
                be accessed, viewed, disclosed, altered, or destroyed by breach of any of our physical, technical, or
                managerial safeguards.
            </p>

            <h3>International data transfer</h3>
            <p>
                We are headquartered in the United States and may use service providers that operate in other countries.
                Your personal information may be transferred to the United States or other locations where privacy laws
                may not be as protective as those in your state, province, or country.
            </p>

            <h3>
                Children
            </h3>
            <p>
                The Service is not intended for use by anyone under 18 years of age. If you are a parent or guardian of
                a child from whom you believe we have collected personal information in a manner prohibited by law,
                please contact us. If we learn that we have collected personal information through the Service from a
                child without the consent of the child’s parent or guardian as required by law, we will comply with
                applicable legal requirements to delete the information.
            </p>

            <h3>Changes to this Privacy Policy</h3>
            <p>
                We reserve the right to modify this Privacy Policy at any time. If we make material changes to this
                Privacy Policy, we will notify you by updating the date of this Privacy Policy and posting it on the
                Service or other appropriate means. Any modifications to this Privacy Policy will be effective upon our
                posting the modified version (or as otherwise indicated at the time of posting). In all cases, your use
                of the Service after the effective date of any modified Privacy Policy indicates your acceptance of the
                modified Privacy Policy.
            </p>


        </div>
    )

}

export default PrivacyPolicyContainer;
