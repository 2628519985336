import * as React from 'react';
import {useParams} from "react-router-dom";
import {bold} from "../../Utils/HtmlUtils";


interface PaymentConfirmationProps {
    paymentIntentId: string
}


export const PaymentConfirmationContainer: React.FunctionComponent = (props) => {
    const {paymentIntentId} = useParams<PaymentConfirmationProps>();


    return <div>
        <h1>Thank you for your payment!!!</h1>
        <p>{bold('paymentIntentId')} {paymentIntentId}</p>
    </div>
};

export default PaymentConfirmationContainer;

