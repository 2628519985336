import {getApiEnv} from "../Utils/ApiEnv";
import {noAuthEmail} from "./auth-utils";

export const backendCall = async (
    url: string,
    method: string = 'GET',
    queryParams: any = {},
    body: any = {}
) => {
    let fullURL = `${getApiEnv().httpEndpoint}/${url}?`;

    Object.keys(queryParams).forEach((key, index) => {
        fullURL = `${fullURL}&${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
    });

    // console.log('FULL URL = ', fullURL);

    try {
        const result = await (await fetch(
            fullURL,
            {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                     'Accept': 'application/json',
                },
                body: JSON.stringify(body)
            }
        )).json();
        const error = null

        return {result, error}

    } catch (e) {
        const error = e
        console.error('Something happened when fetching URL = ', fullURL)
        console.log(e)
        // alert(`Something went wrong when fetching data from the server, ERROR = ${e}, URL = ${fullURL}`)
        const result = null
        return {result, error}
    }
}


export const todayInYYYYMMDDMinusDays = (numDays: number) => {
    const date = new Date();
    date.setDate(date.getDate() - numDays);
    return date.toISOString().slice(0, 10);
}

export const shortDateStringToDateObject = (shortDate: string): Date => {
    let date = new Date(shortDate);
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 1);

    return date
}

export const shortDateStringToDateObjectWithoutShift = (shortDate: string): Date => {
    let date = new Date(shortDate);
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate());

    return date
}

export const utcToLocal = (utcDate: string): Date | any => {
    if (utcDate) {
        let localDate = new Date(utcDate.substring(0, 19))

        // check if hours and minutes are 00:00:00
        if (localDate.getHours() === 0 && localDate.getMinutes() === 0) {
            return localDate;
        }

        let offsetHours = localDate.getTimezoneOffset() / 60
        localDate.setHours(localDate.getHours() - offsetHours)
        return localDate;
    } else {
        return {}

    }

}

export const utcStringToLocalString = (utcDate: string): string => {
    let date = utcToLocal(utcDate);
    if (date) {
        return dateToLocalString(date);
    } else {
        return ''
    }

}

export const dateToLocalString = (date: Date): string => {
    return `${date.toLocaleString()} (EST)`

}

export const dateStringToLocalString = (date: string): string => {
    return dateToLocalString(new Date(date));


}

export const utcDateStringToLocalDateString = (utcDate: string): string => {
    return new Date(utcDate).toLocaleDateString();

}

export const utcDateStringToLocalDateStringPlus1Day = (utcDate: string): string => {
    let date = new Date(utcDate);
    date.setDate(date.getDate() + 1);
    return date.toLocaleDateString();
}

export const authSuccessful = (authUser: any, authPending: boolean): boolean => {
    if (authPending) return false;
    else {
        if (authUser.email === noAuthEmail) {
            return false
        } else {
            return true
        }

    }


}

export const todayInYYYYMMDD = () => {
    return todayInYYYYMMDDMinusDays(0);
}

export const roundToFixed2 = (x: number): string => {
    return roundToFixed(x, 2)
}

export const roundToFixed4 = (x: number): string => {
    return roundToFixed(x, 4)
}

export const roundToFixed = (x: number, num_decimals: number): string => {
    return (Math.round(x * (10 ** num_decimals)) / (10 ** num_decimals)).toFixed(num_decimals);

}


export const getRedAndGreenColorValues = (value: string) => {
    let n = Math.round(+value)
    if (n > 50) n = 50
    if (n < -50) n = -50

    let g_color = Math.round((255 * (50 + n)) / 150)
    let r_color = Math.round((255 * (50 - n)) / 150)

    return [r_color, g_color]
}

export const columnTitle = (columnTitle: string, columnTooltip: string) => {
    return columnTitle
}


export const numberToUSD = (num: number): string => {
    if (num) {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(num);
    } else {
        return ''
    }
}


