import * as React from 'react';
import * as ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import App from './Components/App';
import awsconfig from './aws-exports';
import LogRocket from 'logrocket';
import "@fontsource/urbanist";

// For AWS Cognito User Management
Amplify.configure(awsconfig);

// For LogRocket session recordings
LogRocket.init('no-post-office/nopostoffice-app');


ReactDOM.render(
    <div
        style={{
            fontFamily: "Arial, sans-serif",
        }}

    >
        <App/>
    </div>,
    document.getElementById('root')
);
