import * as React from 'react';
import {BrowserRouter as Router, Route, Redirect, Switch} from 'react-router-dom';
import {BrowserView, MobileView} from "react-device-detect";
import Navigation from "../Components/Shared/Navigation";
import {ThemeContext} from "../ContextProviders/ThemeContextProvider";
import AppContainerDesktop from "../Containers/AppContainer";
import PaymentConfirmationContainer from "../Containers/PaymentConfirmationContainer";
import SignUpContainer from "../Containers/SignUpContainer";
import TutorialContainer from "../Containers/TutorialContainer";
import PricingContainer from "../Containers/PricingContainer";
import Footer from "../Components/Shared/Footer";
import TermsOfServiceContainer from "../Containers/TermsOfServiceContainer";
import PrivacyPolicyContainer from "../Containers/PrivacyPolicyContainer";

const AppRouter = () => {
    const queryParams = new URLSearchParams(window.location.search);
    let hideNavigation = false;
    let hideFooter = false;

    if (queryParams.get('hideNavigation') && queryParams.get('hideNavigation') === '1') {
        hideNavigation = true
    }

    if (queryParams.get('hideFooter') && queryParams.get('hideFooter') === '1') {
        hideFooter = true
    }

    const {theme, setTheme} = React.useContext(ThemeContext);


    return (
        <div
            style={{
                backgroundColor: theme.getPrimaryBackgroundColor(),
                color: theme.getTextColor(),
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
            }}
        >
            <Router>

                {hideNavigation ? null : <Navigation/>}

                <Switch>


                    {/*Sitemap Routes Crawled by Google for SEO*/}
                    <Route exact={true} path="/rates">
                        <BrowserView>
                            <AppContainerDesktop
                                blogWidthPercent={80}
                                contentWidthPercent={90}
                                videoHeightPx={350}
                                fontSizePx={18}
                            />
                        </BrowserView>

                        <MobileView>
                            <AppContainerDesktop
                                blogWidthPercent={90}
                                contentWidthPercent={90}
                                videoHeightPx={200}
                                fontSizePx={16}
                            />
                        </MobileView>

                    </Route>


                    {/*Accounts & Login Routes*/}
                    <Route exact={true} path="/signup" component={SignUpContainer}/>

                    <Route exact={true} path="/tutorial" component={TutorialContainer}/>

                    <Route exact={true} path="/pricing" component={PricingContainer}/>

                    <Route exact={true} path="/terms" component={TermsOfServiceContainer}/>

                    <Route exact={true} path="/privacy" component={PrivacyPolicyContainer}/>


                    <Route exact={true} path="/payment-confirmed/:paymentIntentId">
                        <PaymentConfirmationContainer></PaymentConfirmationContainer>
                    </Route>


                    <Route path="/">
                        <Redirect to="/rates"/>
                    </Route>

                </Switch>
            </Router>

            {hideNavigation ? null : <Footer/>}

        </div>
    );
};


export default AppRouter;
