import React from "react";
import {ThemeContext} from "../../ContextProviders/ThemeContextProvider";
import {Button} from "antd";
import {bold} from "../../Utils/HtmlUtils";


type PricingContainerProps = {}
const PricingContainer = (props: PricingContainerProps) => {

    const {theme, setTheme} = React.useContext(ThemeContext);

    const commonPricingStyles = {
        margin: '2%',
        padding: '2%',
        border: `3px solid ${theme.getBorderColor()}`,
        borderRadius: '20px',
        width: '25%',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',

    } as any


    return <div
        style={{
            marginTop: '2%',
            marginBottom: '5%',
            textAlign: 'center',
        }}
    >
        <div>
            <h1>Pricing</h1>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                }}
            >

                <div
                    style={commonPricingStyles}
                >
                    <h2>Starter</h2>

                    <div>
                        <p>For personal shipping needs and companies just starting out with E-Commerce</p>
                        <p>{bold('No Monthly Fee')}</p>
                        <p>Pay per Label</p>
                        <p>No Sign Up Required</p>
                    </div>

                    <Button

                        variant={'solid'}
                        color={'danger'}
                        href={'/rates'}
                        target={'_blank'}


                    >
                        {bold('Get a CHEAP Label Now!')}
                    </Button>
                </div>

                <div
                    style={commonPricingStyles}
                >
                    <h2>Premium</h2>

                    <div>
                        <p>For companies shipping 100 - 10,000 parcels monthly</p>
                        <p>{bold('$99 / month')}</p>
                        <p>1 Week FREE Trial</p>
                        <p>AI Features</p>
                        <p>Cheaper Labels</p>
                        <p>Priority Support</p>
                    </div>

                    <Button
                        variant={'solid'}
                        color={'primary'}
                        target={'_blank'}
                        href={'/signup'}
                    >
                        {bold('Sign up for FREE Trial!')}
                    </Button>

                </div>

                <div
                    style={commonPricingStyles}
                >
                    <h2>Enterprise</h2>

                    <div>
                        <p>For Companies shipping +10,000 parcels monthly from a warehouse facility</p>
                        <p>WMS Integrations, ROI Analysis and more "White Glove Services"</p>
                        <p>If we don't save you money, you don't pay!</p>

                        <iframe
                            src="https://www.youtube.com/embed/mKe6aUi3DbM?autoplay=1&mute=1"
                            allow={"autoplay"}
                            title={'Medusa Case Study Video'}
                            style={{
                                marginTop: '5%',
                                marginBottom: '5%',
                                width: '90%',
                                height: '220px',
                                marginLeft: '5%',
                            }}
                        />
                    </div>


                    <Button
                        variant={'outlined'}
                        color={'primary'}
                        href={'https://calendly.com/tom-3muv/perseuss-demo'}
                        target={'_blank'}
                        style={{}}
                    >
                        {bold('Schedule a Demo!')}
                    </Button>
                </div>

            </div>

        </div>
    </div>


}
export default PricingContainer;
