export class Theme {
    private themeName: string;
    private primaryBackgroundColor: string;
    private secondaryBackgroundColor: string;
    private textColor: string;
    private borderColor: string;
    private headerTextColor: string;
    private headerBackgroundColor: string


    public constructor(
        themeName: string,
        primaryBackgroundColor: string,
        secondaryBackgroundColor: string,
        textColor: string,
        borderColor: string,
        headerTextColor: string,
        headerBackgroundColor: string,
    ) {
        this.themeName = themeName;
        this.primaryBackgroundColor = primaryBackgroundColor;
        this.secondaryBackgroundColor = secondaryBackgroundColor
        this.textColor = textColor;
        this.borderColor = borderColor;
        this.headerTextColor = headerTextColor;
        this.headerBackgroundColor = headerBackgroundColor;
    }

    public getPrimaryBackgroundColor(): string {
        return this.primaryBackgroundColor;
    }

    public getSecondaryBackgroundColor(): string {
        return this.secondaryBackgroundColor;
    }

    public getTextColor(): string {
        return this.textColor;
    }

    public getBorderColor(): string {
        return this.borderColor;
    }

    public getThemeName(): string {
        return this.themeName;
    }

    public getHeaderTextColor(): string {
        return this.headerTextColor;
    }


    public getHeaderBackgroundColor(): string {
        return this.headerBackgroundColor;
    }

}

export const darkTheme = new Theme(
    'dark',
    '#3b3b3b',
    '#f0f0f0',
    '#ffffff',
    '#1856ff',
    '#1856ff',
    '#c9e5ff'
);


export const lightTheme = new Theme(
    'light',
    '#ffffff',
    '#f0f0f0',
    '#000000',
    '#1856ff',
    '#1856ff',
    '#c9e5ff'
);

export const themeMap = {
    'dark': darkTheme,
    'light': lightTheme
}

export const getThemeByName = (themeName: 'dark' | 'light'): Theme => {
    return themeMap[themeName];
}